import React, { useState } from 'react';

type CustomToggleProps = React.HTMLProps<HTMLAnchorElement>;

export const CustomToggle = React.forwardRef<
  HTMLAnchorElement,
  CustomToggleProps
>(({ children, onClick, 'aria-label': label }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    }}
    aria-label={label}
  >
    {children}
  </a>
));

type CustomMenuProps = React.HTMLProps<HTMLDivElement>;

export const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child: any) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
