export class AppLocalStorage {
  static setValue(key: string, value: any) {
    localStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    );
  }

  static getObject<T>(key: string): T {
    return JSON.parse(localStorage[key]) as T;
  }

  static getString(key: string): string {
    return localStorage && localStorage[key];
  }

  static getNumber(key: string): number | undefined {
    if (localStorage && localStorage[key]) {
      return Number(localStorage[key]);
    }
    return undefined;
  }

  static removeValue(key: string) {
    return localStorage.removeItem(key);
  }
}

export class AppSessionStorage {
  static setValue(key: string, value: any) {
    sessionStorage.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    );
  }

  static getObject<T>(key: string): T {
    return JSON.parse(sessionStorage[key]) as T;
  }

  static getString(key: string): string {
    return sessionStorage[key];
  }

  static removeValue(key: string) {
    return sessionStorage.removeItem(key);
  }
}
