import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// english
import jobsEN from './locales/en/jobs.json';
import driverEN from './locales/en/driver.json';
import fleetEN from './locales/en/fleet.json';
import commonEN from './locales/en/common.json';
import privacyPolicyEN from './locales/en/privacyPolicy.json';
import termsOfServiceEN from './locales/en/termsOfService.json';
import carreerEN from './locales/en/carreer.json';
import nearbyJobsEN from './locales/en/nearbyJobs.json';
import subscriptionEN from './locales/en/subscription.json';
import fleetProfileEN from './locales/en/fleetProfile.json';

// russian
import jobsRU from './locales/ru/jobs.json';
import driverRU from './locales/ru/driver.json';
import fleetRU from './locales/ru/fleet.json';
import commonRU from './locales/ru/common.json';
import privacyPolicyRU from './locales/ru/privacyPolicy.json';
import termsOfServiceRU from './locales/ru/termsOfService.json';
import carreerRU from './locales/ru/carreer.json';
import nearbyJobsRU from './locales/ru/nearbyJobs.json';
import subscriptionRU from './locales/ru/subscription.json';
import fleetProfileRU from './locales/ru/fleetProfile.json';

// spanish
import jobsES from './locales/es/jobs.json';
import driverES from './locales/es/driver.json';
import fleetES from './locales/es/fleet.json';
import commonES from './locales/es/common.json';
import privacyPolicyES from './locales/es/privacyPolicy.json';
import termsOfServiceES from './locales/es/termsOfService.json';
import carreerES from './locales/es/carreer.json';
import nearbyJobsES from './locales/es/nearbyJobs.json';
import subscriptionES from './locales/es/subscription.json';
import fleetProfileES from './locales/es/fleetProfile.json';

// filipino
import jobsFIL from './locales/fil/jobs.json';
import driverFIL from './locales/fil/driver.json';
import fleetFIL from './locales/fil/fleet.json';
import commonFIL from './locales/fil/common.json';
import privacyPolicyFIL from './locales/fil/privacyPolicy.json';
import termsOfServiceFIL from './locales/fil/termsOfService.json';
import carreerFIL from './locales/fil/carreer.json';
import nearbyJobsFIL from './locales/fil/nearbyJobs.json';
import subscriptionFIL from './locales/fil/subscription.json';
import fleetProfileFIL from './locales/fil/fleetProfile.json';

// punjabi
import jobsPA from './locales/pa/jobs.json';
import driverPA from './locales/pa/driver.json';
import fleetPA from './locales/pa/fleet.json';
import commonPA from './locales/pa/common.json';
import privacyPolicyPA from './locales/pa/privacyPolicy.json';
import termsOfServicePA from './locales/pa/termsOfService.json';
import carreerPA from './locales/pa/carreer.json';
import nearbyJobsPA from './locales/pa/nearbyJobs.json';
import subscriptionPA from './locales/pa/subscription.json';
import fleetProfilePA from './locales/pa/fleetProfile.json';

// vietnamese
import jobsVI from './locales/vi/jobs.json';
import driverVI from './locales/vi/driver.json';
import fleetVI from './locales/vi/fleet.json';
import commonVI from './locales/vi/common.json';
import privacyPolicyVI from './locales/vi/privacyPolicy.json';
import termsOfServiceVI from './locales/vi/termsOfService.json';
import carreerVI from './locales/vi/carreer.json';
import nearbyJobsVI from './locales/vi/nearbyJobs.json';
import subscriptionVI from './locales/vi/subscription.json';
import fleetProfileVI from './locales/vi/fleetProfile.json';

// chinese
import jobsZH from './locales/zh/jobs.json';
import driverZH from './locales/zh/driver.json';
import fleetZH from './locales/zh/fleet.json';
import commonZH from './locales/zh/common.json';
import privacyPolicyZH from './locales/zh/privacyPolicy.json';
import termsOfServiceZH from './locales/zh/termsOfService.json';
import carreerZH from './locales/zh/carreer.json';
import nearbyJobsZH from './locales/zh/nearbyJobs.json';
import subscriptionZH from './locales/zh/subscription.json';
import fleetProfileZH from './locales/zh/fleetProfile.json';

const detectionOptions = {
  order: ['navigator'],
};

const resources = {
  en: {
    jobs: jobsEN,
    driver: driverEN,
    fleet: fleetEN,
    common: commonEN,
    privacyPolicy: privacyPolicyEN,
    termsOfService: termsOfServiceEN,
    carreer: carreerEN,
    nearbyJobs: nearbyJobsEN,
    subscription: subscriptionEN,
    fleetProfile: fleetProfileEN,
  },
  es: {
    jobs: jobsES,
    driver: driverES,
    fleet: fleetES,
    common: commonES,
    privacyPolicy: privacyPolicyES,
    termsOfService: termsOfServiceES,
    carreer: carreerES,
    nearbyJobs: nearbyJobsES,
    subscription: subscriptionES,
    fleetProfile: fleetProfileES,
  },
  ru: {
    jobs: jobsRU,
    driver: driverRU,
    fleet: fleetRU,
    common: commonRU,
    privacyPolicy: privacyPolicyRU,
    termsOfService: termsOfServiceRU,
    carreer: carreerRU,
    nearbyJobs: nearbyJobsRU,
    subscription: subscriptionRU,
    fleetProfile: fleetProfileRU,
  },
  fil: {
    jobs: jobsFIL,
    driver: driverFIL,
    fleet: fleetFIL,
    common: commonFIL,
    privacyPolicy: privacyPolicyFIL,
    termsOfService: termsOfServiceFIL,
    carreer: carreerFIL,
    nearbyJobs: nearbyJobsFIL,
    subscription: subscriptionFIL,
    fleetProfile: fleetProfileFIL,
  },
  pa: {
    jobs: jobsPA,
    driver: driverPA,
    fleet: fleetPA,
    common: commonPA,
    privacyPolicy: privacyPolicyPA,
    termsOfService: termsOfServicePA,
    carreer: carreerPA,
    nearbyJobs: nearbyJobsPA,
    subscription: subscriptionPA,
    fleetProfile: fleetProfilePA,
  },
  vi: {
    jobs: jobsVI,
    driver: driverVI,
    fleet: fleetVI,
    common: commonVI,
    privacyPolicy: privacyPolicyVI,
    termsOfService: termsOfServiceVI,
    carreer: carreerVI,
    nearbyJobs: nearbyJobsVI,
    subscription: subscriptionVI,
    fleetProfile: fleetProfileVI,
  },
  zh: {
    jobs: jobsZH,
    driver: driverZH,
    fleet: fleetZH,
    common: commonZH,
    privacyPolicy: privacyPolicyZH,
    termsOfService: termsOfServiceZH,
    carreer: carreerZH,
    nearbyJobs: nearbyJobsZH,
    subscription: subscriptionZH,
    fleetProfile: fleetProfileZH,
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  detection: detectionOptions,
});

export default i18n;
