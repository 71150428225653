import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ESegmentCategory, track } from 'services/analytics';
import i18next from '../../i18n';
import languages, { ELanguages } from '../../utils/languages';
import LanguageIcon from '../LanguageIcon';
import { CustomMenu, CustomToggle } from '../CustomDropdown';
import { AppLocalStorage } from '../../utils/storage';

interface LanguageDropdownProps {
  classType: string;
}

const LanguageDropdown = ({ classType }: LanguageDropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, i18n] = useTranslation('common');
  const [currentLanguage, setCurrentLanguage] = useState(ELanguages.ENGLISH);

  useEffect(
    // substring as English is considered en-US in i18n
    () => setCurrentLanguage(i18n.language as ELanguages),
    [i18n.language]
  );

  useEffect(() => {
    const lng = AppLocalStorage.getString('_lng');
    if (lng) {
      setTimeout(() => i18next.changeLanguage(lng), 100);
      return;
    }
    document.documentElement.lang = 'en';
  }, []);

  const onChangeLanguage = (eventKey: string | null) => {
    if (eventKey) {
      i18next.changeLanguage(eventKey);
      AppLocalStorage.setValue('_lng', eventKey);
      document.documentElement.lang = eventKey;

      track(ESegmentCategory.SELECT_LANG, { lang: eventKey });
    }
  };

  return (
    <Dropdown onSelect={onChangeLanguage} className={classType}>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        aria-label="Change Language"
      >
        <LanguageIcon language={currentLanguage} />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu}>
        {languages.map(({ name, code }) => {
          return (
            <Dropdown.Item
              onSelect={() => setCurrentLanguage(code)}
              key={code}
              eventKey={code}
            >
              {name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
