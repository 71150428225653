import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import LanguageDropdown from '../LanguageDropdown';
import JobsStatesDropdown from '../JobsStatesDropdown';
import config from '../../config';

export default function Header() {
  const [t] = useTranslation('common');
  const [display, setDisplay] = useState('d-none');
  const [signInLink, setSignInLink] = useState(config.signInDriver);
  const router = useRouter();
  const [currentRoute, setCurrentRoute] = useState(router.pathname);

  const onOpenMenu = useCallback((e) => {
    if (e.target.checked) {
      setDisplay('d-inline-block');
    } else {
      setDisplay('d-none');
    }
  }, []);

  const handleHeaderShadow = useCallback(() => {
    const headerEl = document.getElementById('header');
    const offset = window.pageYOffset;

    if (offset) {
      headerEl?.classList.add('header-shadow');
    } else {
      headerEl?.classList.remove('header-shadow');
    }
  }, []);

  useEffect(() => {
    if (router.asPath === '/fleets') {
      setSignInLink(config.signInFleet);
    }
    setCurrentRoute(router.pathname);
  }, [router]);

  useEffect(() => {
    window.addEventListener('scroll', handleHeaderShadow);
  });

  return (
    <header className="header" id="header" style={{ position: 'sticky' }}>
      <div className="container">
        <div className="row">
          <div className="col d-block d-lg-flex">
            <a href="/cdl-drivers" className="logo">
              <Image
                src="/images/v2/logo.svg"
                alt="haul"
                width={145}
                height={49}
              />
            </a>
            <LanguageDropdown classType={`mobile-lang ${display}`} />
            <input
              className="menu-btn"
              type="checkbox"
              id="menu-btn"
              onClick={onOpenMenu}
            />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon" />
              <span className="navicon-2" />
              <span className="navicon-3" />
            </label>
            <ul className="menu menu-nav">
              <li className="nav-item">
                <a
                  href="/cdl-drivers"
                  className={
                    currentRoute === '/cdl-drivers' ? 'active' : 'non-active'
                  }
                >
                  <span>{t('drivers')}</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/fleets"
                  className={
                    currentRoute === '/fleets' ? 'active' : 'non-active'
                  }
                >
                  <span>{t('forFleets')}</span>
                </a>
              </li>
              <li className="d-block d-lg-none d-xl-none nav-item">
                <a href="/nearby-jobs">
                  <span>{t('nearbyJobs')}</span>
                </a>
              </li>
              <li className="d-xs-block nav-item">
                <JobsStatesDropdown />
              </li>
              <li className="nav-item">
                <a href="/blog" className="blog-link">
                  <span>{t('news')}</span>
                </a>
              </li>
              <li className="nav-item lang">
                <LanguageDropdown classType="desk-lang" />
              </li>
              <li className="nav-item signin">
                <a href={signInLink}>
                  <img
                    width={26}
                    height={26}
                    src="/images/v2/user.svg"
                    alt="Signin"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
