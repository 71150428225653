import React from 'react';
import { ELanguages } from 'utils/languages';

interface LanguageIconProps {
  language: ELanguages;
}

export default function LanguageIcon({
  language = ELanguages.ENGLISH,
}: LanguageIconProps) {
  return (
    <img
      width={26}
      height={26}
      src={`/images/languages/${language}.svg`}
      alt="lang"
    />
  );
}
