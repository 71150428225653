import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { Children } from 'react';

interface ActiveLinkProps {
  as: string;
  href: string;
  router: any;
  children: JSX.Element;
}

export default withRouter(
  ({ router, children, as, href, ...rest }: ActiveLinkProps) => (
    <Link {...rest} href={href} as={as} legacyBehavior>
      {React.cloneElement(Children.only(children), {
        className:
          router.asPath === href || router.asPath === as ? 'active' : null,
      })}
    </Link>
  )
);
