import clsx from 'clsx';
// import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import LinkComponent from 'components/LinkComponent';
import { CustomMenu, CustomToggle } from 'components/CustomDropdown';

import styles from './index.module.scss';

function JobsStatesDropdown() {
  const [t] = useTranslation('common');

  const usStates = [
    { name: 'California', code: 'CA' },
    { name: 'Georgia', code: 'GA' },
    { name: 'Texas', code: 'TX' },
    { name: 'Pennsylvania', code: 'PA' },
    { name: `${t('more')}...`, code: 'ALL' },
  ];

  const renderOptions = (
    label: string,
    href: string,
    key: string,
    classes?: string
  ) => {
    return (
      <Dropdown.Item
        className={clsx(styles.item, classes)}
        key={key}
        href={href}
      >
        <LinkComponent href={href} as={href}>
          <div>{label}</div>
        </LinkComponent>
      </Dropdown.Item>
    );
  };
  return (
    <Dropdown className={styles.root}>
      <Dropdown.Toggle as={CustomToggle} className={styles.test}>
        {/* Span needed for styling purposes */}
        <span>{t('jobs')}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu} className={styles.dropdownMenu}>
        {renderOptions(
          t('nearbyJobs'),
          '/nearby-jobs',
          'nearbyJobs',
          'd-none d-md-block d-lg-block, d-xl-block'
        )}
        {usStates.map(({ name, code }) =>
          renderOptions(name, `/cdl-drivers/cdl-jobs/${code}`, code)
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default JobsStatesDropdown;
