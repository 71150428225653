export enum ELanguages {
  ENGLISH = 'en-US',
  CHINESE = 'zh',
  PUNJABI = 'pa',
  RUSSIAN = 'ru',
  SPANISH = 'es',
  FILIPINO = 'fil',
  VIETNAMESE = 'vi',
}

const languages = [
  {
    code: ELanguages.ENGLISH,
    name: 'English',
  },
  {
    code: ELanguages.CHINESE,
    name: '中文',
  },
  {
    code: ELanguages.PUNJABI,
    name: 'ਪੰਜਾਬੀ',
  },
  {
    code: ELanguages.RUSSIAN,
    name: 'Русский',
  },
  {
    code: ELanguages.SPANISH,
    name: 'Español',
  },
  {
    code: ELanguages.FILIPINO,
    name: 'Tagalog',
  },
  {
    code: ELanguages.VIETNAMESE,
    name: 'Tiếng Việt',
  },
];

export default languages;
